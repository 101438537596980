import React from 'react';
import Header from './components/Header';
import Royalties from './components/Royalties';
import './App.css';
import {StateProvider} from './utils/web3'

function App() {
  return (
    <StateProvider>
      <div className="w-screen back overflow-x-hidden">
        <Header></Header>
        <Royalties></Royalties>
      </div>
    </StateProvider>
  );
}

export default App;
