/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import Background from "./Background";
import { useStateContext } from "../utils/web3";
import {Alert, AlertTitle, Dialog, Snackbar} from '@mui/material';
import GoBack from "../assets/Go-Back.png"
import SoldOut from "../assets/Key.png"
import notAcrive from "../assets/Mint-Deshabilitado.png"
import mintButton from "../assets/Mint.png"
import loader from "../assets/loader.gif"

function Royalties() {
  const {
    account,
    mint,
    isPayedMintOpen,
    isSoldOut,
  } = useStateContext();
  const [amount, setAmount] = useState<number>(1);
  const [wCap, setWCap] = useState<number>(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isSold, setIsSold] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isNotReady, setIsNotReady] = useState(false);
  const [isNotWhitlisted, setIsNotWhitlisted] = useState(false);
  const [transactionHash, setTransactionHash] = useState("");
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    if (account) {
      getData()
    } else {
      setIsActive(false);
    }
  }, [ account ]);

  const getData = async () => {
    const isSold = await isSoldOut();
    if ( isSold ) {
      setIsSold(true)
    } else {
      const isPayed = await isPayedMintOpen();
      if (!isPayed) {
        setIsNotReady(true)
      } else {
        if (isPayed) {
          setIsActive(true)
        }
      }
    }
  }

  const handlePlus = async () => {
    if (isActive && amount < 10) {
        setAmount(amount+1)
    }
  }

  const handleMinus = () => {
    if (isActive && amount > 1) {
      setAmount(amount-1)
    }
  }

  const cum = async () => {
    setIsLoading(true);
    let tx = false;
    try {
      tx = await mint(amount);
    } catch(e) {
      console.log(e);
      setIsLoading(false);
      setOpenAlert(true);
    }
    if(tx) {
      setWCap(wCap-amount);
      setIsOpen(false);
      setIsLoading(false);
      setIsSuccess(true);
      //setTransactionHash(tx.transactionHash);
      const isFreeMint = await isPayedMintOpen();
      if(amount === 4 && !isFreeMint) {
        setIsNotReady(true);
        setIsActive(false);
      }
      setAmount(1);
    }
  }

  return (
    <>
      <div className="flex flex-col justify-start items-center w-full h-4/5 z-10 pb-20">
        <div className="text-7xl md:text-3xl text-yellow-500 lowercase text-center leading-none">
          MINT YOUR <br></br> MISTERY KEY
        </div>
        <div className="flex flex-row items-center">
          <button className="text-7xl text-yellow-500 lowercase" onClick={handleMinus}>
            -
          </button>
          <img src={SoldOut} alt="" className="pass"/>
          <button className="text-7xl text-yellow-500 lowercase" onClick={handlePlus}>
            +
          </button>
        </div>
        <div className="text-7xl text-yellow-500 lowercase">
          {amount}
        </div>
        <div className="text-6xl md:text-xl text-yellow-500 lowercase secondary-text leading-tight">
          COST: 0.025 ETH + GAS FEES
        </div>
        <div className="text-6xl md:text-xl text-yellow-500 lowercase secondary-text leading-tight">
          TOTAL COST: {(0.025 * (amount as any)).toFixed(3)} ETH + GAS FEES
        </div>
        <div className="text-6xl md:text-xl text-yellow-500 lowercase secondary-text leading-tight mb-3">
          TOTAL SUPPLY: 1900
        </div>
        {
          !isActive &&
          <button onClick={() => {}} className="mint-button">
              <img src={notAcrive} alt="" className="h-full w-auto"/>
          </button>
        }
        {
          isActive &&
          (
            loading ?
            <img src={loader} alt="" className="mint-button loader"/>
            :
            <button onClick={cum} className="mint-button">
                <img src={mintButton} alt="" className="h-full w-auto"/>
            </button>
          )
        }
      </div>
      <Dialog open={isSold} onClose={() => setIsSold(false)} PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}>
        <div className="flex flex-col items-center">
          <div className="text-7xl text-white lowercase secondary-text">
            SOLD OUT
          </div>
          <button onClick={() => setIsSold(false)} className="mint-button">
              <img src={GoBack} alt="" className="h-full w-auto"/>
          </button>
        </div>
      </Dialog>
      <Snackbar message="Here is snackbar message" open={openAlert} autoHideDuration={30000} onClose={() => {setOpenAlert(false)}}>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Ups there was an error sending your transaction. Please try again later
        </Alert>
      </Snackbar>
      <Dialog open={isSuccess} onClose={() => setIsSuccess(false)} PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}>
          <div className="flex flex-col items-center">
            <a href={`https://etherscan.io/tx/${transactionHash}`} target="_blank" rel="noopener noreferrer" className="w-full">
              <div className="text-7xl md:text-5xl text-white lowercase secondary-text">
                mint successfully
              </div>
            </a>
            <button onClick={() => setIsSuccess(false)} className="mint-button">
                <img src={GoBack} alt="" className="h-full w-auto"/>
            </button>
          </div>
      </Dialog>
      <Dialog open={isNotReady} onClose={() => setIsNotReady(false)} PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}>
          <div className="flex flex-col items-center">
            <a href={`https://etherscan.io/tx/${transactionHash}`} target="_blank" rel="noopener noreferrer" className="w-full">
              <div className="text-7xl md:text-5xl text-white lowercase secondary-text">
                mint is not ready
              </div>
            </a>
            <button onClick={() => setIsNotReady(false)} className="mint-button">
                <img src={GoBack} alt="" className="h-full w-auto"/>
            </button>
          </div>
      </Dialog>
      <Dialog open={isNotWhitlisted} onClose={() => setIsNotWhitlisted(false)} PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}>
          <div className="flex flex-col items-center">
            <div className="text-7xl md:text-5xl text-white lowercase text-center secondary-text">
              you are not in the whitlist. <br /> come back later
            </div>
            <button onClick={() => setIsNotWhitlisted(false)} className="mint-button">
                <img src={GoBack} alt="" className="h-full w-auto"/>
            </button>
          </div>
      </Dialog>
      <Background></Background>
    </>
  )
}

export default Royalties;